import { useStatusBanner } from "@/components/StatusBannerProvider";
import { IoMdClose } from "react-icons/io";
import { twMerge } from "tailwind-merge";

const StatusBanner = () => {
  const { banner, setIsDismissed } = useStatusBanner();

  if (!banner) return null;

  const baseStyles = "fixed top-0 left-0 right-0 z-50 px-4 py-2 h-16 text-lg";

  return (
    <div className={twMerge(baseStyles, banner.style)}>
      <div className="mx-auto flex h-full items-center justify-between">
        <div className="flex-grow text-center">{banner.text}</div>
        <button
          onClick={() => {
            setIsDismissed(true);
          }}
          className="absolute right-4 top-1/2 -translate-y-1/2"
          aria-label="Dismiss banner"
        >
          <IoMdClose className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default StatusBanner;
